import {AffiliateProduct, getValueFromBrowser} from "./trackingQueryHook.js";
import {APP_SERVER_URL} from "../../../config";

const getSiteConfig = async (type) => {
  const params = new URLSearchParams({ type });
  try {
    const response = await fetch(`${APP_SERVER_URL}site/config?${params}`);
    return await response.json();
  } catch (error) {
    throw new Error('Error:', error);
  }
};

export const getAppLink =  (needLinkForIOSApp = true) => {
	const userProduct =
		+getValueFromBrowser('product') ||
		+getValueFromBrowser('product_1') ||
		+getValueFromBrowser('product_2') ||
		1;
	const isSportUser = userProduct === AffiliateProduct.Sport;
	if (needLinkForIOSApp) {
		const link = isSportUser
			? 'https://apps.apple.com/mx/app/winpot-sport/id6446956929'
			: 'https://apps.apple.com/mx/app/winpot-casino/id1632635281';
    window.open(link, '_blank');
	} else {
    getSiteConfig('android').then(response => {
      if (response) {
        const androidAppLink = response?.android?.url;
        const link = isSportUser ? androidAppLink.sport : androidAppLink.casino;
        window.open(link, '_self');
      }
    });
	}
};
