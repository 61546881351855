import { isFilledInputs } from "../js/popups/activate_forms";
import {
    getValueFromBrowser,
    setSignUpBanner,
    setValueToBrowser
} from "./trackingQueryHook";
import {sendGtagEvent} from "./gtag";

const resetInputs = (obj) => {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            resetInputs(obj[key]);
        } else {
            obj[key] = false;
        }
        if (key === '') {
            delete obj[key];
        }
    }
}

const resetErrors = () => {
    const errors = document.querySelectorAll('.error__message');
    const dateErrors = document.querySelectorAll('.signup__birthdate__row .error-message');
    const dobError = document.querySelector('.error__message.signup-dob');
    [...errors, ...dateErrors, dobError].forEach((error) => {
        error.innerHTML = '';
        error.classList.remove('show');
    });
}

const resetSignUpSteps = () => {
    const firstStep = document.querySelector('#SignUp .first-step-section');
    const secondStep = document.querySelector('#SignUp .second-step-section');
    const progressIndicator = document.querySelector('.progress-indicator');
    const progressIndicatorLine = document.querySelector('.progress-indicator-line');
    const progressIndicatorInactive = document.querySelector('.progress-indicator_inactive');
    const banner = document.querySelector('#SignUp .popup__banner');
    const button = document.querySelector('#signup-form-submit-button');
    if (!firstStep.classList.contains('is__active')) {
        secondStep.classList.remove('is__active');
        firstStep.classList.add('is__active');
        [progressIndicator, progressIndicatorLine, progressIndicatorInactive, banner].forEach((elem) => {
            elem?.classList.remove('second-step');
        });
        progressIndicator.removeEventListener('click', resetSignUpSteps);
        resetErrors();
        button.disabled = false;
    }
}

const closeAllPopups = () => {
    document.querySelectorAll('[data-popup].is__open').forEach((openPopup) => {
        openPopup.classList.remove('is__open');
        const forms = document.querySelectorAll('form');
        forms.forEach((form) => form.reset());
        resetInputs(isFilledInputs);
        resetErrors();
        const modalFromUrl = getQueryParamFromUrl('modal');
        if (modalFromUrl) {
            removeQueryParamFromUrl('modal');
        }
        if (openPopup.id === 'SignUp') {
            sendGtagEvent("sign_up_form_closed", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            resetSignUpSteps();
            const signUpButton = document.querySelector('#signup-form-submit-button');
            signUpButton.disabled = false;
        } else if (openPopup.id === 'Login') {
            sendGtagEvent("login_form_closed", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            const loginButton = document.querySelector('#login-form-submit-button');
            loginButton.disabled = true;
        }
        if (openPopup.id === 'ForgotPassword' || openPopup.id === 'SignUp') {
            const hintPasswordElements = document.querySelectorAll('.signup-hint-password');
            hintPasswordElements.forEach((hint) => {
                hint.classList.remove('success');
                hint.classList.remove('unsuccessful');
            });
        }
    });
};

const openPopupById = (popupId, product) => {
    const popup = document.getElementById(popupId);
    if (popup) {
        if (popupId === 'SignUp') {
            sendGtagEvent("sign_up_form_opened", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
            setValueToBrowser('product', product === 'sport' ? 2 : 1) ;
            setSignUpBanner(popup);
        } else if (popupId === 'Login') {
            sendGtagEvent("login_form_opened", {
                affiliate_id: getValueFromBrowser("affiliateId") || ""
            });
        }
        popup.classList.add('is__open');
        addQueryParamToUrl('modal', popupId);
    }
};

const getQueryParamFromUrl = (key) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
}

const addQueryParamToUrl = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, null, newUrl);
};

const removeQueryParamFromUrl = (key) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, null, newUrl);
};

const checkIfAuthPopup = (popupId) => {
    return popupId === 'SignUp' || popupId === 'Login' || popupId === 'ForgotPassword';
};

export {
    resetSignUpSteps,
    closeAllPopups,
    openPopupById,
    removeQueryParamFromUrl,
    getQueryParamFromUrl,
    checkIfAuthPopup
};
