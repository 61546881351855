const footerAccordion = document.querySelector('.accordion.footer__info');

footerAccordion.addEventListener('click', () => {
  footerAccordion?.classList.toggle('collapsed');
});

const sidemenuAccorions = document.querySelectorAll('.sidemenu .accordion');

sidemenuAccorions.forEach((accordion) => {
  const accordionHeader = accordion.querySelector(".accordion__header");
  accordionHeader.addEventListener("click", () => {
    accordion.classList.toggle("open");
    const accordionBody = accordion.querySelector(".accordion__body");
    if (accordionBody && accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
    }
  });
});
