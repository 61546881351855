import { SCREEN_WIDTH } from '../utils/consts';

const burgerButton = document.querySelector('.burger');
const sideMenu = document.querySelector('.sidemenu');
const overlay = document.querySelector('.overlay');

const toggle = (element) => {
  element?.classList.toggle('is__open');
}

const closeSideMenu = () => {
  burgerButton?.classList.remove('is__open');
  sideMenu?.classList.remove('is__open');
  overlay?.classList.remove('is__open');
}

burgerButton?.addEventListener('click', () => {
  toggle(burgerButton);
  toggle(sideMenu);
  toggle(overlay);
});

overlay?.addEventListener('click', closeSideMenu);

let resize;
window.addEventListener('resize', () => {
  clearTimeout(resize);
  resize = setTimeout(() => {
    if (window.innerWidth > SCREEN_WIDTH.DESKTOP_S) {
      closeSideMenu();
    }   
  }); 
});
